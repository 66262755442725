import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import ofIcon from "../../Assets/of.svg";

function Home() {
  const { t } = useTranslation();
  return (
    <section>
      <Container fluid className="home-about-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <div className="text-container">
                <h1 style={{ paddingBottom: 5 }} className="heading">
                  {t("hello")} <span role="img">❤️</span>
                </h1>
                <br />

                <h1 className="subheading">
                  {t("welcome")}
                  <span className="purple-text">{t("welcome_you")}</span>
                  {t("welcome_1")}
                </h1>
                <br />
                <h1 className="subheading">
                  {t("home_1")}
                  <span className="purple-text">{t("home_2")}</span>
                  {t("home_3")}
                  <br />
                  {t("home_4")}
                  <span className="purple-text">{t("secret")}</span>
                  {t("home_9")}
                  <br />
                  <br />
                  {t("home_5")}
                  <br />
                  <br />
                  {t("home_6")}
                  <Link to="/Site" className="purple-text no-underline">
                    {t("home_7")}{" "}
                  </Link>{" "}
                  {t("home_8")}
                </h1>
                <br />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>{/* Hier können Sie neuen Text einfügen */}</h1>

              <ul className="home-about-social-links">
                <li className="social-icons">
                  <SocialIcon
                    url="https://x.com/lialove1988"
                    className="icon-colour home-social-icons"
                  />
                </li>
                <li className="social-icons">
                  <SocialIcon
                    url="https://t.me/+HCVHObqKHqFlYjA0"
                    className="icon-colour home-social-icons"
                  />
                </li>
                <li className="social-icons">
                  <a
                    href="https://onlyfans.com/lia_loove"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-colour-of home-social-icons"
                  >
                    <img src={ofIcon} alt="of-icon" className="icon-image" />
                  </a>
                </li>
                <li className="social-icons">
                  <SocialIcon
                    url="https://www.instagram.com/lialoveoffi"
                    className="icon-colour home-social-icons"
                  />
                </li>
              </ul>
              <p>
                <span className="purple-text">{t("offers_17")}</span>
                <span className="purple">{t("offers_18")} </span>
                <span className="purple-text">{t("offers_19")} </span>
              </p>
            </Col>
          </Row>
        </Container>
        <Particle />
      </Container>
    </section>
  );
}

export default Home;
