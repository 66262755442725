import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SiteCard from "./SiteCards";
import Particle from "../Particle";
import onlyFans from "../../Assets/Sites/onlyfans.png";
//import onlyFansFree from "../../Assets/Sites/onlyfansfree.png";
import bestFans from "../../Assets/Sites/bestfans.jpg";
import telegram from "../../Assets/Sites/telegram.png";
//import fourBased from "../../Assets/Sites/4based.png";
//import fancci from "../../Assets/Sites/fancci.png";
//import mym from "../../Assets/Sites/mym.png";
import { useTranslation } from "react-i18next";

function Sites() {
  const { t } = useTranslation();
  return (
    <Container fluid className="Site-section">
      <Particle />
      <Container>
        <h1 className="Site-heading">
          {t("my")} {t("site")}
        </h1>
        <p className="purple-text">{t("more")}</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="Site-card">
            <SiteCard
              imgPath={bestFans}
              isBlog={false}
              title="Bestfans"
              demoLink="https://www.bestfans.com/lialove"
            />
          </Col>
          <Col md={4} className="Site-card">
            <SiteCard
              imgPath={onlyFans}
              isBlog={false}
              title="OnlyFans"
              demoLink="https://onlyfans.com/lia_loove"
            />
          </Col>
          <Col md={4} className="Site-card">
            <SiteCard
              imgPath={telegram}
              isBlog={false}
              title="Telegram"
              demoLink="https://t.me/+HCVHObqKHqFlYjA0"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Sites;
